<template>
  <v-container class="pa-0 ma-0 px-0 mx-0" fluid>
    <v-card class="py-0">
      <v-card-text class="pa-0">
        <v-row no-gutters>
          <!-- start: statistics -->
          <v-col class="pb-0 mb-0" cols="12">
            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card elevation-0 mb-0 pb-0">
              <div class="no-gutters row">
                <v-progress-linear v-if="loading" class="pa-0 ma-0" indeterminate/>
                <div class="col-md-12 col-lg-6">
                  <ul class="list-group list-group-flush">
                    <li class="bg-transparent list-group-item">
                      <div class="widget-content p-0">
                        <div class="widget-content-outer">
                          <div class="widget-content-wrapper">
                            <div class="widget-content-left">
                              <div class="widget-numbers  pr-3">
                                <font-awesome-icon :icon="['fal', 'calendar']" class="text-success "
                                                   style="font-size: 35px"/>
                              </div>
                            </div>
                            <div class="widget-content-left">
                              <div class="widget-heading text-uppercase">{{ $t('generic.lang_total') }}</div>
                            </div>
                            <div class="widget-content-right">
                              <div class="widget-numbers text-success">
                                <countTo :duration='3000' :endVal='Number(statistics.total)'
                                         :startVal='0'></countTo>
                                {{$store.getters['settings/currencyData']('symbol')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="bg-transparent list-group-item">
                      <div class="widget-content p-0">
                        <div class="widget-content-outer">
                          <div class="widget-content-wrapper">
                            <div class="widget-content-left">
                              <div class="widget-numbers  pr-3">
                                <font-awesome-icon :icon="['fal', 'globe']" class="text-info "
                                                   style="font-size: 35px"/>
                              </div>
                            </div>

                            <div class="widget-content-left">
                              <div class="widget-heading text-uppercase">{{ $t('generic.lang_online') }}</div>
                            </div>
                            <div class="widget-content-right">
                              <div class="widget-numbers text-info">
                                <countTo :duration='3000' :endVal='Number(statistics.totalOnline)'
                                         :startVal='0'></countTo>
                                {{$store.getters['settings/currencyData']('symbol')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-md-12 pb-0 mb-0 col-lg-6">
                  <ul class="list-group list-group-flush">
                    <li class="bg-transparent list-group-item">
                      <div class="widget-content p-0">
                        <div class="widget-content-outer">
                          <div class="widget-content-wrapper">
                            <div class="widget-content-left">
                              <div class="widget-numbers  pr-3">
                                <font-awesome-icon :icon="['fal', 'credit-card']" class="text-danger "
                                                   style="font-size: 35px"/>
                              </div>
                            </div>
                            <div class="widget-content-left">
                              <div class="widget-heading text-uppercase">{{ $t('generic.lang_creditCard') }}</div>
                            </div>
                            <div class="widget-content-right">
                              <div class="widget-numbers text-danger">
                                <countTo :duration='3000' :endVal='Number(statistics.totalCard)'
                                         :startVal='0'></countTo>
                                {{$store.getters['settings/currencyData']('symbol')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="bg-transparent list-group-item">
                      <div class="widget-content p-0">
                        <div class="widget-content-outer">
                          <div class="widget-content-wrapper">
                            <div class="widget-content-left">
                              <div class="widget-numbers  pr-3">
                                <font-awesome-icon :icon="['fal', 'euro-sign']" class="text-warning "
                                                   style="font-size: 35px; color: purple !important"/>
                              </div>
                            </div>

                            <div class="widget-content-left">
                              <div class="widget-heading text-uppercase">{{ $t('generic.lang_cash') }}</div>
                            </div>
                            <div class="widget-content-right">
                              <div class="widget-numbers" style="color: purple !important">
                                <countTo :duration='3000' :endVal='Number(statistics.totalCash)'
                                         :startVal='0'></countTo>
                                {{$store.getters['settings/currencyData']('symbol')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </v-col>
          <!-- end: statistics -->

          <!-- start: filter -->
          <v-col class="pt-0" cols="12">
            <v-form ref="filtersForm" lazy-validation>
              <v-row class="pa-0 ma-0 mx-0">
                <v-col cols="12" class="px-0 pt-0">
                  <v-divider class="pt-0 mt-0"/>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="startDateTime" :disabled="this.dates_loading"
                                :label="$t('accounting.lang_dailyReportStart')"
                                :loading="this.dates_loading"
                                :rules="[v => !!v ]" dense outlined
                                type="date"/>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="endDateTime" :disabled="this.dates_loading"
                                :label="$t('accounting.lang_dailyReportEnd')"
                                :loading="this.dates_loading"
                                :rules="[v => !!v]" dense outlined
                                type="date"/>
                </v-col>

              </v-row>
            </v-form>
          </v-col>
          <!-- end: filter -->

          <!-- start: datatable -->
          <v-col class="pb-0 mb-0" cols="12">
            <Datatable ref="tableBeeOverview"
                       :api-endpoint="ENDPOINTS.DATATABLES.TABLEBEE.STATISTICS"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       excel-file-name="tableBee-overview"
            >
              <template v-slot:item.total="{item}">
                {{ Number(item.total)|currency }}
              </template>
            </Datatable>
          </v-col>
          <!-- end: datatable -->
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Datatable from "@/components/datatable/Datatable.vue";
import countTo from 'vue-count-to';
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCalendar, faCreditCard, faEuroSign, faGlobe,} from '@fortawesome/pro-light-svg-icons';
import moment from "moment";

library.add(faCalendar, faEuroSign, faCreditCard, faGlobe,);
//'invoiceUUID', 'invoiceSaleID', 'saleTimeStamp', 'sellerID', 'customerData', 'itemQuantity', 'invoiceTotal', 'typeOfPayment', 'tableName', 'invoiceAufsHaus', 'cashierID', 'invoiceType'

export default {
  name: "TablebeeOverviewComponent",
  components: {Datatable, countTo, FontAwesomeIcon},
  data() {
    return {
      ENDPOINTS,
      startDateTime: "",
      endDateTime: "",
      dates_loading: false,
      statistics: {
        total: 0,
        totalOnline: 0,
        totalCard: 0,
        totalCash: 0,
      },
      loading: false,
    }
  },
  computed: {
    datatableHeaders() {
      return [
        {
          text: this.$t('generic.lang_id'),
          align: 'left',
          value: "uuid",
          hide: true
        },
        {text: this.$t('generic.lang_id'), value: "id", hide: true},
        {text: this.$t('generic.lang_date'), value: "date"},
        {text: this.$t('generic.lang_time'), value: "time"},
        {text: this.$t('generic.lang_seller_id'), value: "sellerID", hide: true},
        {text: this.$t('generic.lang_customer'), value: "cust", hide: true},
        {text: this.$t('generic.lang_qtyOfSoldItems'), value: "cust", hide: true},
        {text: this.$t('erp.lang_ware_total'), value: "total"},
        {text: this.$t('accounting.lang_paymenthod'), value: "payment"},
        {text: this.$t('generic.lang_table'), value: "tableName"},
      ];
    },
    excelColumns() {
      return [
        {label: this.$t('generic.lang_id'), field: "id"},
        {label: this.$t('generic.lang_date'), field: "date"},
        {label: this.$t('generic.lang_time'), field: "time"},
        {label: this.$t('erp.lang_ware_total'), field: "total",},
        {label: this.$t('accounting.lang_paymenthod'), field: "payment",},
        {label: this.$t('generic.lang_table'), field: "tableName",},
      ];
    },
    params: function () {
      return {
        start_date: this.startDateTime === "" ? "" : this.$moment.unix(new Date(this.startDateTime).getTime() / 1000).startOf("days").unix(),
        end_date: this.endDateTime === "" ? "" : this.$moment.unix(new Date(this.endDateTime).getTime() / 1000).endOf("days").unix(),
      }
    }
  },
  watch: {
    startDateTime(val) {
      this.$refs.filtersForm.validate();

      if (this.$moment(val).isValid() && this.$moment(this.endDateTime).isValid() && this.$moment(val).isBefore(this.$moment(this.endDateTime))) {
        this.$refs.tableBeeOverview.getDataFromApi(this.params);
        this.loadStatistics();
      } else if (this.$moment(val).isValid() && this.$moment(this.endDateTime).isValid() && this.$moment(val).isAfter(this.$moment(this.endDateTime)))
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_select_date_before') + " : " + this.endDateTime
        });
    },
    endDateTime(val) {
      this.$refs.filtersForm.validate();

      if (this.$moment(val).isValid() && this.$moment(this.startDateTime).isValid() && this.$moment(val).isAfter(this.$moment(this.startDateTime))) {
        this.$refs.tableBeeOverview.getDataFromApi(this.params);
        this.loadStatistics();
      } else if (this.$moment(val).isValid() && this.$moment(this.startDateTime).isValid() && this.$moment(val).isBefore(this.$moment(this.startDateTime)))
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_select_date_after') + " : " + this.startDateTime
        });
    },
  },
  methods: {
    loadStatistics() {
      this.loading = true;
      this.axios.post(ENDPOINTS.TABLEBEE.OVERVIEW.STATISTICS, {}, {
        params: this.params
      })
          .then((res) => {
            if (res.data.success) {
              this.statistics = res.data.data[0];
              this.$forceUpdate();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    getDate(timeStamp) {
      return moment.unix(timeStamp).format('DD.MM.YY HH:mm')
    },
  },
  mounted() {
    this.loadStatistics();
  }
}
</script>

<style scoped>

</style>
